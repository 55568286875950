import { Album, Artist, SpotifyApi } from '@spotify/web-api-ts-sdk';
import { useQuery } from '@tanstack/react-query';
import { Batcher, create, keyResolver, windowScheduler } from '@yornaath/batshit';
import { useSpotifySdk } from '../contexts/SpotifySdkProvider';

let artistsBatcher: Batcher<Artist[], string, Artist> | null = null;

const getArtistBatcher = (sdk: SpotifyApi) => {
  if (artistsBatcher) {
    return artistsBatcher;
  }
  artistsBatcher = create({
    name: 'artists',
    fetcher: async (artistIds: string[]) => sdk.artists.get(artistIds),
    resolver: keyResolver('id'),
    scheduler: windowScheduler(10),
  });
  return artistsBatcher;
};

export const useArtistQuery = (artistId: string) => {
  const sdk = useSpotifySdk();
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['artists', artistId],
    queryFn: () => sdk && getArtistBatcher(sdk).fetch(artistId),
  });
};

export const useArtistAlbumsQuery = (artistId: string) => {
  const sdk = useSpotifySdk();
  return useQuery({
    queryKey: ['artist-albums', artistId],
    queryFn: async () => {
      const albums: Album[] = [];
      const albumResult = await sdk.artists.albums(artistId, undefined, undefined, 50);
      albumResult.items.forEach((item) => albums.push(item as unknown as Album));
      // Substract the page we already got at the end
      const maxPages = Math.min(Math.ceil(albumResult.total / 50), 10) - 1;
      // Create sequence starting by 50 and incrementing by 50 until we reach the max
      const sequence = Array.from(Array(maxPages).keys()).map((i) => (i + 1) * 50);
      console.log('sequence', sequence);
      console.log('albumresult', albumResult);
      // Fetch all pages in parallel
      const otherItems = await Promise.all(
        sequence.map(async (offset) => {
          const result = await sdk.artists.albums(artistId, undefined, undefined, 50, offset);
          return result.items;
        })
      );
      // Flatten the array of arrays
      const otherAlbums = otherItems.flat() as unknown as Album[];
      return albums.concat(otherAlbums);
    },
  });
};

export const useCanControlAirplayQuery = () =>
  useQuery({
    queryKey: ['can-control-airplay'],
    queryFn: async () => {
      let reachable = false;
      try {
        fetch('https://jam.staudtc.de/health').then((response) => {
          if (response.ok) {
            console.log('jam reachable');
            reachable = true;
          }
        });
      } catch (error) {
        console.log('https://jam.staudtc.de/health is not reachable');
      }
      return reachable;
    },
  });

export const useDevicesQuery = () => {
  const sdk = useSpotifySdk();
  const reachable = useCanControlAirplayQuery();
  return useQuery({
    queryKey: ['devices', reachable],
    queryFn: async () => {
      console.log('Refetching devices');
      const result = await sdk.player.getAvailableDevices();
      if (!result) {
        return { active: null, all: [] };
      }
      const activeDevice = result.devices.find((d) => d.is_active);
      return { active: activeDevice, all: result.devices };
    },
  });
};

export const usePlaybackStateQuery = () => {
  const sdk = useSpotifySdk();
  return useQuery({
    queryKey: ['playback-state'],
    queryFn: () => {
      console.log('Refetching playback state');
      return sdk.player.getPlaybackState();
    },
    refetchInterval: 3000,
  });
};
