import { Button, Card, CircularProgress, Input } from '@mui/joy';
import { Album } from '@spotify/web-api-ts-sdk';
import { IconChevronLeft, IconHome, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { useSpotifySdk } from '../../contexts/SpotifySdkProvider';
import artists from '../../data/artists.json';
import { queryClient } from '../../queries/queryClient';
import {
  useArtistAlbumsQuery,
  useArtistQuery,
  useDevicesQuery,
  usePlaybackStateQuery,
} from '../../queries/spotify';
import Group from '../Group/Group';
import { useState } from 'react';

function ArtistImage({ artistId }: { artistId: string }) {
  const artistResult = useArtistQuery(artistId);
  if (artistResult.isLoading) return null;
  if (artistResult.isError) return null;
  const artist = artistResult.data;
  if (!artist) return null;
  return <img src={artist.images[0].url} alt={artist.name} height={45} width={45} />;
}

function ArtistDetails({ id }: { id: string }) {
  const artistResult = useArtistQuery(id);
  const albumsResult = useArtistAlbumsQuery(id);
  const playbackResult = usePlaybackStateQuery();
  const [searchString, setSearchString] = useState('');

  const result = useDevicesQuery();
  const device = result.data?.active;
  const deviceId = device?.id;

  const sdk = useSpotifySdk();
  const playMutation = useMutation({
    mutationFn: async ({ album }: { album: Album }) => {
      await sdk.player.startResumePlayback(deviceId, album.uri, undefined, { position: 0 }, 0);
      console.log('Switched track');
      await sdk.player.setRepeatMode('context', deviceId);
      await sdk.player.togglePlaybackShuffle(false, deviceId);
      console.log('Set repeat mode');
      queryClient.invalidateQueries({ queryKey: ['playback-state'] });
    },
  });

  if (!deviceId) {
    return <>No device id found</>;
  }

  // generate a table for the results

  if (artistResult.isLoading || albumsResult.isLoading) {
    return (
      <Group styles={{ marginTop: '20px' }}>
        <CircularProgress />
      </Group>
    );
  }
  if (artistResult.isError || albumsResult.isError) return <p>error</p>;
  const artist = artistResult.data;
  const albums = albumsResult.data;
  if (!artist || !albums) return null;
  const filteredAlbums =
    searchString !== ''
      ? albums.filter((album) => album.name.toLowerCase().includes(searchString.toLowerCase()))
      : albums;
  const currentlyPlaying = playbackResult.data?.context?.uri;
  const otherArtists = artists.filter((artistItem) => artistItem.id !== id).slice(0, 4);
  return (
    <>
      <Card component={Link} to="/" style={{ color: 'black', textDecoration: 'none' }}>
        <Group>
          <IconChevronLeft size="3rem" stroke={1.5} />
          <IconHome size="2.5rem" stroke={1.5} />
          {otherArtists.map((artistItem) => (
            <ArtistImage artistId={artistItem.id} key={artistItem.id} />
          ))}
        </Group>
      </Card>

      <Group>
        <h2>{artist.name}</h2>
      </Group>
      <Group>
        <img src={artist.images[0].url} alt={artist.name} height={80} width={80} />
      </Group>
      <Group styles={{ marginTop: '10px' }}>
        <Input
          value={searchString}
          onChange={(event) => setSearchString(event.target.value)}
          placeholder="Search"
        />
        <Button onClick={() => setSearchString('')} disabled={searchString === ''}>
          <IconX />
        </Button>
      </Group>

      <h3>Alben</h3>
      {filteredAlbums.map((album) => (
        <Card
          key={album.id}
          variant="soft"
          style={{
            border: currentlyPlaying === album.uri ? '5px solid green' : 'none',
            marginBottom: '12px',
            position: 'relative',
          }}
          onClick={() => !playMutation.isPending && playMutation.mutate({ album })}
        >
          {playMutation.isPending && playMutation.variables?.album.id === album.id && (
            <div
              style={{
                position: 'absolute',
                display: 'flex',
                inset: 0,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
              }}
            >
              <CircularProgress sx={{ position: 'relative' }} />
            </div>
          )}

          <Group styles={{ width: '100%' }}>
            <img src={album.images[0].url} alt={album.name} height={150} />
          </Group>
          <Group>{album.name}</Group>
        </Card>
      ))}
    </>
  );
}

export default ArtistDetails;
